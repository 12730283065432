import React from "react";
import TopBar from '../../common/topbar/topbar';
import BottomAppBar from "pages/home/components/bottomAppBar/bottomAppBar";
import './about.scss';
import HorizontalScroller from "pages/home/components/horizontalScroller/horizontalScroller";
import constants from "js/constants";
import routerUtils from "js/routerUtils";
import BaseWebComponents from "common/BaseComponents";

class AboutWidget extends BaseWebComponents {
    toWeb() {
        routerUtils.openPage(constants.DAYOUDAN);
    }

    componentDidMount() {

    }

    goReg() {
        routerUtils.openPage(constants.REG_DAYOUDAN)
    }

    renderView() {
        return (
            <div className="fade">
                <TopBar tabIndex={4}></TopBar>
                <div className="banner-about flex flex-direction">
                    <div className="scroll-handle" style={{ height: '60px' }}></div>
                    <div className="heart-width flex justify-center flex-direction banner-block text-left" style={{ margin: '98px auto auto' }}>
                        <h1 className="main-text" >大有关于我们</h1>
                        <p className="p-text">全渠道聚合订单管理&配送服务系统，聚合配送运力供应商，轻松管理所有订单，一键发起多运力配送，全链路解决订单、库存、配送、运单、售后问题</p>
                        <div className="btn-free flex-on-center hand" onClick={this.toWeb.bind(this)}>免费注册</div>
                    </div>
                </div>
                <div>
                    <div className="company-title min-heart-width">公司介绍</div>
                    <div className="company-bg flex justify-center">
                        <div className="flex align-center heart-width">
                            <img src={require('assets/about/company_building.png')} className="company-img"></img>
                            <div className="company-desc-right">
                                <p>湖南迪嘉科技有限公司是一家专注于智能同城聚合配送SAAS服务平台及系列应用系统产品研究的国家高新技术企业、国家科技型中小企业、国家规上企业、湖南省专精特新企业，通过ISO9001质量认证。公司注册资金1000万，自成立以来，深耕于同城聚合即时配送、即时服务等领域，坚持自主研发、设计、生产、销售，不断推出基于人工智能、大数据分析、云计算技术的同城聚合即时配送信息化行业解决方案，拥有发明专利两项，获得饿了么2023年度优秀服务商荣誉。</p>
                                <p>迪嘉科技“大有单——场景式聚合订单全链路管理SAAS平台”是聚合全网电商渠道、同城配送运力平台，可以一键呼叫顺丰、达达、美团、蜂鸟、UU、闪送等全国十余家运力骑手，城市覆盖全，区域运力强，各平台同时抢单、各平台同时比价、配送又快又省钱。服务的客户群体为全国有外卖业务的商家，覆盖商业集团、连锁商户、中小商户等，商户类别覆盖有餐饮美食、烘焙蛋糕、龙虾烧烤、甜品饮品、浪漫鲜花、果蔬生鲜、超市便利、夜宵小吃和异国料理等。</p>
                                <div className="free-main-btn hand" onClick={this.goReg.bind(this)}>免费注册</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-heart-width map-head">
                    <div className="map-title">业务范围</div>
                    <div className="map-title-desc">无论身在何处，都能获得大有单一键发单的便利服务</div>
                </div>
                <div className="map-bg flex justify-center min-heart-width">
                    <div className="heart-width flex justify-center">
                        <div className="map-wraper flex">
                            <div className="map-tags-container">
                                <img src={require('assets/map_desc1.png')} className="map-desc" />
                                <img src={require('assets/map_desc2.png')} className="map-desc" />
                                <img src={require('assets/map_desc3.png')} className="map-desc" />
                            </div>
                            <img src={require('assets/about/map_cn.png')} className="map-img" />
                        </div>
                    </div>
                </div>
                <HorizontalScroller></HorizontalScroller>

                <div className="contact-us min-heart-width flex flex-direction align-center">
                    <div className="title flex-on-center min-heart-width">联系我们</div>
                    <div className="flex-align-center bottom-container min-heart-width">
                        <img src={require('assets/about/map_location.png')} className="map-location-img" />
                        <div className="bottom-container-right">
                            <div className="flex-align-center text-left bottom-container-right-item">
                                <img src={require('assets/map_icon_addr.png')} className="map-icon" />
                                <div className="bottom-container-right-item-right">
                                    地址
                                    <div className="bottom-container-right-item-right-desc">湖南省长沙市岳麓区世贸希尔顿大楼12楼</div>
                                </div>
                            </div>
                            <div className="flex-align-center text-left bottom-container-right-item">
                                <img src={require('assets/map_icon_email.png')} className="map-icon" />
                                <div className="bottom-container-right-item-right">
                                    邮箱
                                    <div className="bottom-container-right-item-right-desc">cs@nadayou.com</div>
                                </div>
                            </div>
                            <div className="flex-align-center text-left bottom-container-right-item">
                                <img src={require('assets/map_icon_call.png')} className="map-icon" />
                                <div className="bottom-container-right-item-right">
                                    电话
                                    <div className="bottom-container-right-item-right-desc">0731-85382466</div>
                                </div>
                            </div>
                            <div className="flex-align-center text-left bottom-container-right-item">
                                <img src={require('assets/about/zs.png')} className="map-icon" />
                                <div className="bottom-container-right-item-right">
                                    商务合作
                                    <div className="bottom-container-right-item-right-desc">{constants.PHONE_BUSINESS}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomAppBar></BottomAppBar>
            </div>
        )
    }
}

export default AboutWidget;